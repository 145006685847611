import React, { Component, Fragment } from "react";
// const BrowserHistory = require('react-router/lib/BrowserHistory').default;
import axios from "axios";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import {
  Hero,
  Header,
  TopNav,
  EventTimer,
  JoinUs,
  ServiceSection,
  Donate,
  NewsLetter,
  Footer,
  // ErrorPage,
  Testimonial,
  EventSlider,
} from "../../custom";

import './style.css';
/*----------------
MEMBERS IMPORT
----------------- */
import Member from "../member";
// declare constant for event api
const eventAPI = "./utils/eventData.json";
const TestimonialAPI = "./utils/testimonyData.json";


/*----------------
MEMBERS IMPORT
----------------- */
class Home extends Component {
  // SET STATE FOR EVENT
  state = {
    eventItemSlide: [],
    testimonyItem: [],
    loading: false,
    bibleVerse: [],
    error: null,
  };
  //EVENT NEWS SLIDER
  componentDidMount() {
    //fetch event item and store it in the state
    this.setState({ loading: true }, () => {
      axios.get(eventAPI).then((result) =>
        this.setState({
          loading: false,
          eventItemSlide: [...result.data],
        })
      );
    });

    this.setState({ loading: true }, () => {
      axios.get(TestimonialAPI).then((testimony) =>
        this.setState({
          loading: false,
          testimonyItem: [...testimony.data],
        })
      );
    });

   }

  //EVENT NEWS SLIDER
  render() {
    const { eventItemSlide, loading, testimonyItem } = this.state;
    return (
      <>
      <Helmet>
        <title>Glory Plus</title>
        <meta name="description" content="Home " />
      </Helmet>
      <TopNav />
      <Header />
      <Hero />
      <EventTimer />
      {/* <JoinUs fade="zoom-in" duration="1300" ease="ease-in-sine" /> */}

      <section className="about-section" style={{ marginBottom: 50, backgroundColor: "rgb(255, 255, 255)" }}>
        <div className="container">
          <div className="row" style={{paddingTop: 50 }}>
            <div className="col-md-6 about-content"><br/><p> <b>GloryPlus International Presents Holy Ghost Morning </b> <br/>
            It is time for your supernatural turn-around, <br/>
            Get ready to be transformed in Holy Ghost Morning! <br/>
             Anticipate a powerful move of the Spirit of God, <br/>
             bringing liberation, miraculous healings, and incredible signs and wonders. <br/>
             This is your chance for a divine encounter that will change your life forever. <br/>
              Don't miss this extraordinary moment with the Holy Spirit. <br/>
              Come Expectant. <br/>
             <b>Date: 6th April 2025.</b>  <br/>
             <b>Time: 8am</b> <br/>
              <strong>Venue : 18 Kudirat-Abiola way Olusosun, Ikeja, Lagos</strong>
          

        
            </p></div><p></p>
            <div className="col-md-6 about-img">
              <div className="embed-responsive embed-responsive-16by9 card">
                <video controls="" poster="img/ggm.jpg" className="embed-responsive-item vid-img">
                <source src="hgm.mp4" type="video/mp4" />Your browser does not support the video tag.</video>
              </div>
            </div>
            
            </div></div></section>
    
      <ServiceSection />

      <section className="blog-section mb-3 pt-5" style={{backgroundColor: "#fff", paddingTop: 30}}>
        <div className="container"><div className="section-title mt-5"><h3>Highlights From Holy Ghost Morning</h3></div>
        <div className="row">
          <div className="col-sm-6 col-md-4">
            <div className="sermon-item event-div">
              <div className="embed-responsive embed-responsive-4by3 card">
                <iframe title="YouTube Video" width="560" height="315" src="https://www.youtube.com/embed/O4Y0ioKbpns?showinfo=0" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen="" sandbox="allow-same-origin allow-scripts allow-popups allow-forms" poster="img/hgmh.jpg"></iframe>
                </div>
              </div>
            </div>
            <div className="col-sm-6 col-md-4">
              <div className="sermon-item event-div">
                <div className="embed-responsive embed-responsive-4by3 card">
                  <iframe title="YouTube Video" width="560" height="315" src="https://www.youtube.com/embed/yk08GuGhNSM?showinfo=0" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen="" sandbox="allow-same-origin allow-scripts allow-popups allow-forms" poster="img/hgmh.jpg"></iframe>
                  </div>
                </div>
              </div>
            <div className="col-sm-6 col-md-4">
              <div className="sermon-item event-div">
                <div className="embed-responsive embed-responsive-4by3 card">
                  <iframe title="YouTube Video" width="560" height="315" src="https://www.youtube.com/embed/6RB_h9ZWdCM?showinfo=0" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen="" sandbox="allow-same-origin allow-scripts allow-popups allow-forms" poster="img/hgmh.jpg"></iframe>
              </div>
            </div>
          </div>
        </div>
        </div>
        </section>

        <section className="about-section mb-5" style={{backgroundColor: 'rgb(245, 248, 249)', paddingTop: 50, paddingBottom: 50}}>
          <div className="container">
            <div className="row">
              <div className="col-md-6 about-content">
                <h2>God Has A Miracle For You Today</h2>
                <p>How can a man be so used by God?<br/>What is the price for God's miracle working power?</p>
                <p>Reverend Dubus uses the story of his life to show how <br/> you can sincerely desire and walk in the supernatural power of God.
                <br/>He is anointed mightily by the power of the Holy Spirit with evidence of<br/>
                diverse miracles, manifestations of the gifts of the Holy Ghost, healings and deliverance.</p>
                <a className="site-btn sb-wide" href="https://selar.co/194933" target="_blank">Grab Your Copy</a></div>
                <div className="col-md-6 about-img" style={{backgroundColor: 'rgb(245, 248, 249)'}}>
                  <div className="embed-responsive embed-responsive-16by9 card">
                    <video controls="" poster="img/ghm.jpg" className="embed-responsive-item vid-img">
                      <source src="ghm4u.mp4" type="video/mp4" />
                      <source src="ghm4u.mp4" type="video/ogg" />
                      Your browser does not support the video tag.
                    </video>
                  </div>
                </div>
              </div>
            </div>
          </section>
      <Donate />
      <NewsLetter />
      <Footer />
    </>
    );
  }
}

export default Home;
